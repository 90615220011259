import * as React from 'react';
import cx from 'classnames';

import moment from 'moment';
import 'moment-timezone';

import Autocomplete from 'react-autocomplete';

const styles = require('./styles.scss');

interface TimePickerProps {
  selectedDate: moment.Moment;
  onChange: (value: moment.Moment) => void;
  disabled?: boolean;
  name?: string;
}

interface TimePickerState {
  value: string;
}

const timePresets = (): string[] => {
  const result = [];
  for (let h = 0; h < 24; h += 1) {
    for (let m = 0; m < 60; m += 15) {
      let hr = `00${h}`;
      let min = `00${m}`;
      hr = hr.substr(hr.length - 2, 2);
      min = min.substr(min.length - 2, 2);
      result.push(`${hr}:${min}`);
    }
  }
  return result;
};

export default class TimePicker extends React.PureComponent<
  TimePickerProps,
  TimePickerState
> {
  static defaultProps = {
    disabled: false,
  };

  constructor(props: TimePickerProps) {
    super(props);
    this.state = {
      value: props.selectedDate.format('HH:mm'),
    };
  }

  static readonly TIME_PRESETS = timePresets();

  renderItem = (item: { value: string }, isHighlighted: boolean) => (
    <div
      className={`${styles.item} ${
        isHighlighted ? styles.itemHighlighted : ''
      }`}
      key={item.value}
    >
      {item.value}
    </div>
  );

  renderMenu = (children: JSX.Element) => (
    <div className={styles.menu}>{children}</div>
  );

  selectValues = () => {
    return timePresets().map((preset) => {
      return { value: preset };
    });
  };

  onSelect = (value: string) => {
    // if input is active on mobile, blur to hide keyboard
    const active = document.activeElement;
    if (active instanceof HTMLElement) {
      active.blur();
    }

    this.setValue(value);
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    this.setValue(value);
  };

  setValue = (value: string) => {
    this.setState({ value });
    if (value.match(/^[012][0-9]:[0-5][0-9]$/)) {
      const [hr, min] = value.split(':', 2);
      this.props.onChange(
        this.props.selectedDate
          .clone()
          .hour(Number.parseInt(hr, 10))
          .minute(Number.parseInt(min, 10)),
      );
    }
  };

  onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    this.setState({ value: this.props.selectedDate.format('HH:mm') });
  };

  onFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (event.target) {
      event.target.select();
    }
  };

  inputField = (props: { className: string; disabled?: boolean }) => (
    <input type="text" {...props} value={this.state.value} />
  );

  timeInput = () =>
    this.props.disabled ? (
      <div className={styles.autoComplete}>
        {this.inputField({
          className: cx('time-input', styles.timeInput),
          disabled: true,
        })}
      </div>
    ) : (
      <Autocomplete
        items={this.selectValues()}
        getItemValue={(item: any) => item.value}
        renderItem={this.renderItem}
        onChange={this.onChange}
        onSelect={this.onSelect}
        value={this.state.value}
        inputProps={{
          className: cx('time-input', styles.timeInput),
          onFocus: this.onFocus,
          onBlur: this.onBlur,
          name: this.props.name,
        }}
        wrapperStyle={{ display: 'block' }}
        menuStyle={{
          position: 'absolute',
          height: '400px',
          overflowY: 'scroll',
          top: 'auto',
          left: 'auto',
          zIndex: 100,
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        }}
      />
    );

  render() {
    return <div className={styles.timePicker}>{this.timeInput()}</div>;
  }
}
